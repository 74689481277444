<template>
  <div v-loading="registering" class="dialog_container">
    <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
      <div class="row no_border">
        <template v-if="isShipper">
          <el-form-item :label="$t('Label.Registered Date')" prop="poolDataUploadDate">
            <el-date-picker v-model="searchForm.poolDataUploadDate" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.L/C No')" prop="lcNo">
            <el-input type="text" v-model="searchForm.lcNo" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Currency Code')" prop="currencyCode">
            <el-input type="text" v-model="searchForm.currencyCode" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Invoice Amount')" prop="currencyCodeAmount">
            <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange amount_range" style="width: 235px;padding-left:4px">
              <el-input-number
                :controls="false"
                placeholder="Min"
                name=""
                class="el-range-input"
                v-model="searchForm.amountMin"
                :min="-maxNum"
                :max="maxNum"
              />
              <span class="el-range-separator">to</span>
              <el-input-number
                :controls="false"
                placeholder="Max"
                name=""
                class="el-range-input"
                v-model="searchForm.amountMax"
                :min="-maxNum"
                :max="maxNum"
              />
            </div>
          </el-form-item>
          <el-form-item :label="$t('Label.Date of Issue')" prop="dateOfIssue">
            <el-date-picker v-model="searchForm.dateOfIssue" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.Date of Amendment')" prop="dateOfAmendment">
            <el-date-picker v-model="searchForm.dateOfAmendment" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item :label="$t('Label.Company ID')" prop="companyId">
            <el-input type="text" v-model.trim="searchForm.companyId" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Company Name')" prop="companyName">
            <el-input type="text" v-model="searchForm.companyName" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Our Ref No (Original)')" prop="ourReferenceNoLcOriginal">
            <el-input type="text" v-model="searchForm.ourReferenceNoLcOriginal" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Serial No')" prop="allNotificationSerialNumbers">
            <el-input type="text" v-model="searchForm.allNotificationSerialNumbers" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Seq No of Total')" prop="sequenceNumberOfTotal">
            <el-input type="text" v-model="searchForm.sequenceNumberOfTotal" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.MT')" prop="messageType">
            <el-select v-model="searchForm.messageType" :class="{ multiple_selected: multipleSelect(searchForm.messageType) }" placeholder="Select" multiple collapse-tags clearable>
              <el-option v-for="messageType in MESSAGE_TYPE_VARIABLES" :key="messageType.code" :label="messageType.label" :value="messageType.code"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </div>
      <div class="row no_border">
        <template v-if="isShipper">
          <el-form-item :label="$t('Label.Company ID')" prop="companyId">
            <el-input type="text" v-model.trim="searchForm.companyId" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Company Name')" prop="companyName">
            <el-input type="text" v-model="searchForm.companyName" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Our Ref No (Original)')" prop="ourReferenceNoLcOriginal">
            <el-input type="text" v-model="searchForm.ourReferenceNoLcOriginal" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Serial No')" prop="allNotificationSerialNumbers">
            <el-input type="text" v-model="searchForm.allNotificationSerialNumbers" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Seq No of Total')" prop="sequenceNumberOfTotal">
            <el-input type="text" v-model="searchForm.sequenceNumberOfTotal" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.MT')" prop="messageType">
            <el-select v-model="searchForm.messageType" :class="{ multiple_selected: multipleSelect(searchForm.messageType) }" placeholder="Select" multiple collapse-tags clearable>
              <el-option v-for="messageType in MESSAGE_TYPE_VARIABLES" :key="messageType.code" :label="messageType.label" :value="messageType.code"> </el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item :label="$t('Label.Registered Date')" prop="poolDataUploadDate">
            <el-date-picker v-model="searchForm.poolDataUploadDate" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.L/C No')" prop="lcNo">
            <el-input type="text" v-model="searchForm.lcNo" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Currency Code')" prop="currencyCode">
            <el-input type="text" v-model="searchForm.currencyCode" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Invoice Amount')" prop="currencyCodeAmount">
            <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange amount_range" style="width: 235px;padding-left:4px">
              <el-input-number
                :controls="false"
                placeholder="Min"
                name=""
                class="el-range-input"
                v-model="searchForm.amountMin"
                :min="-maxNum"
                :max="maxNum"
              />
              <span class="el-range-separator">to</span>
              <el-input-number
                :controls="false"
                placeholder="Max"
                name=""
                class="el-range-input"
                v-model="searchForm.amountMax"
                :min="-maxNum"
                :max="maxNum"
              />
            </div>
          </el-form-item>
          <el-form-item :label="$t('Label.Date of Issue')" prop="dateOfIssue">
            <el-date-picker v-model="searchForm.dateOfIssue" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.Date of Amendment')" prop="dateOfAmendment">
            <el-date-picker v-model="searchForm.dateOfAmendment" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-form-item>
        </template>
      </div>
      <div class="row no_border">
        <el-form-item :label="$t('Label.Applicant')" prop="applicant">
          <el-input type="text" v-model="searchForm.applicant" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Advising Bank ID')" prop="advisingBankId">
          <el-input type="text" v-model="searchForm.advisingBankId" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Advising Bank Name')" prop="advisingBank">
          <el-input type="text" v-model="searchForm.advisingBank" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Bank Branch No')" prop="bankBranchNo">
          <el-input type="text" v-model="searchForm.bankBranchNo" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Beneficiary No')" prop="beneficiaryNo">
          <el-input type="text" v-model="searchForm.beneficiaryNo" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
      </div>
      <div class="row no_border">
        <el-form-item :label="$t('Label.Issuing Bank')" prop="issuingBank">
          <el-input type="text" v-model="searchForm.issuingBank" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Confirming Bank')" prop="requestedConfirmationParty">
          <el-input type="text" v-model="searchForm.requestedConfirmationParty" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item label=" " prop="confirmedLc">
          <el-checkbox style="margin-top:4px" border class="border_none" v-model="searchForm.confirmedLc" true-label="Y" :false-label="null">{{$t('Label.Confirmed L/C')}}</el-checkbox>
        </el-form-item>
        <!-- <el-form-item label=" " prop="linkingFlag">
          <el-checkbox border class="border_none" v-model="searchForm.linkingFlag" :true-label="LINKING_FLAG.LINKED" :false-label="LINKING_FLAG.UNLINKED">{{$t('Label.Linking Flag')}}</el-checkbox>
        </el-form-item> -->
      </div>

      <div class="row no_border drop">
        <tw-button
          type="secondary"
          size="medium"
          icon="search"
          @click="search"
        >
          Search
        </tw-button>
        <tw-button
          type="default"
          size="medium"
          @click="clear"
          style="margin: 8px 16px 16px;"
        >
          Clear
        </tw-button>
      </div>
    </el-form>

    <div style="min-height:388px">
      <!-- <tw-table-system :height="300" :schemas="schemas" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :serverPagination="true" @row-click="register" @paging="onPaging" /> -->
      <tw-table-system
        :height="480"
        :schemas="schemas"
        :items="items"
        :pageSize="limit"
        :totalCount="totalCount"
        :offset="offset"
        :serverPagination="true"
        :selection="true"
        :rowKey="getRowKey"
        @selection-change="selectionChange"
        @row-click="rowClick"
        @paging="onPaging"
        ref="table"
        mode="POOL"
      />

      <div class="row no_border drop register" style="margin-top:16px">
        <tw-button
          v-show="items && items.length"
          type="primary"
          size="medium"
          @click="register"
          :disabled="!selectItem"
        >
          OK
        </tw-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { LC_LINK_FLG } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import { $api } from '@/store/ApiClient';
import { mapState } from 'vuex';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { numberToString, clearSearchForm, moveAt } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/listLcPoolSchema.json';
import dayjs from 'dayjs';

// import items from '@/../public/listLcPoolItem.json'; // スタブ
// let _items = [];
// for (let i = 0; i < 3; i++) {
//   _items = [..._items, ...items];
// }

export default {
  name: 'TwAddLc',
  mixins: [mixinEntityList],
  components: {
    TwButton,
    TwTableSystem
  },
  props: {
    lc: Array
  },
  data() {
    return {
      items: null,
      searchForm: {
        messageType: [],
        sequenceNumberOfTotal: '',
        ourReferenceNoLcOriginal: '',
        allNotificationSerialNumbers: '',
        lcNo: '',
        currencyCode: '',
        amountMin: undefined,
        amountMax: undefined,
        dateOfIssue: '',
        dateOfAmendment: '',
        applicant: '',
        advisingBankId: '',
        advisingBank: '',
        issuingBank: '',
        requestedConfirmationParty: '',
        confirmedLc: null,
        poolDataUploadDate: '',
        companyId: '',
        companyName: '',
        bankBranchNo: '',
        beneficiaryNo: '',
        deleteFlg: '',
        linkingFlag: LC_LINK_FLG.UNLINKED,
        includeDeletedData: false
      },
      limit: 20,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      currentPage: 1,
      maxNum: 10 ** 16, // 300桁
      lastQuery: {},
      selectionItems: [],
      LINKING_FLAG: LC_LINK_FLG,
      // messageType区分値
      MESSAGE_TYPE_VARIABLES: [{ label: 'CoverLetter', code: 'CLT' }, { label: '700', code: '700' }, { label: '701', code: '701' }, { label: '707', code: '707' }, { label: '708', code: '708' }, { label: '710', code: '710' }, { label: '711', code: '711' }, { label: '799', code: '799' }],
      selecting: false,
      selectItem: null,
      registering: false
    };
  },
  computed: {
    ...mapState(['companyRoles']),
    // 荷主か
    isShipper() {
      return this.$store.getters.isShipper;
    },
    schemas() {
      const tableSchemas = _.reject(_.cloneDeep(schemas), table => {
        return table.key === 'tradingFlowId' || table.key === 'processName';
      });
      // 荷主の場合はL/C Noを3列目に
      if (this.isShipper) {
        const index = _.findIndex(tableSchemas, { key: 'documentaryCreditNumber' });
        _.find(tableSchemas, { key: 'documentaryCreditNumber' }).fixed = true;
        return moveAt([...tableSchemas], index, 2);
      }
      return tableSchemas;
    },
    multipleSelect() {
      return model => {
        return _.isArray(model) && model.length > 1;
      };
    }
  },
  created() {
    const queries = this.$store.getters.getQueries('LC_POOL_SELECT');
    if (queries) {
      this.searchForm = queries;
    } else {
      // 検索初期条件：直近1ヶ月昇順
      const today = dayjs()
        .hour(0)
        .minute(0)
        .second(0);
      this.searchForm.poolDataUploadDate = [today.subtract(1, 'month').format(), today.format()];
    }
    this.fetch();
  },
  methods: {
    // BFFに渡す入力値の設定
    transformQueries() {
      const f = this.searchForm;
      return {
        messageType: f.messageType,
        sequenceNumberOfTotal: f.sequenceNumberOfTotal,
        ourReferenceNoLcOriginal: f.ourReferenceNoLcOriginal,
        allNotificationSerialNumbers: f.allNotificationSerialNumbers,
        documentaryCreditNumber: f.lcNo,
        currencyCode: f.currencyCode,
        currencyCodeAmountFrom: numberToString(f.amountMin),
        currencyCodeAmountTo: numberToString(f.amountMax),
        dateOfIssueFrom: f.dateOfIssue && f.dateOfIssue.length ? dayjs(this.getStartDate(f.dateOfIssue)).format('YYMMDD') : null,
        dateOfIssueTo: f.dateOfIssue && f.dateOfIssue.length ? dayjs(this.getEndDate(f.dateOfIssue)).format('YYMMDD') : null,
        amendmentDateFrom: f.dateOfAmendment && f.dateOfAmendment.length ? dayjs(this.getStartDate(f.dateOfAmendment)).format('YYMMDD') : null,
        amendmentDateTo: f.dateOfAmendment && f.dateOfAmendment.length ? dayjs(this.getEndDate(f.dateOfAmendment)).format('YYMMDD') : null,
        applicant: f.applicant,
        advisingBankId: f.advisingBankId,
        advisingBankName: f.advisingBank,
        issuingBankName: f.issuingBank,
        requestedConfirmationParty: f.requestedConfirmationParty,
        confirmedLc: f.confirmedLc,
        registeredDateFrom: this.getStartDate(f.poolDataUploadDate),
        registeredDateTo: this.getEndDate(f.poolDataUploadDate),
        ownerCompanyId: f.companyId,
        ownerCompanyName: f.companyName,
        bankBranchNo: f.bankBranchNo,
        beneficiaryNo: f.beneficiaryNo,
        linkingFlag: String(LC_LINK_FLG.UNLINKED),
        includeDeletedData: f.includeDeletedData
      };
    },
    async fetch(currentPage) {
      // BFF_LCP02 L/Cプール一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/lc-pool-datas/search/{pageLine}/{pageNum}',
          path: {
            pageLine: this.limit,
            pageNum: this.currentPage
          },
          query: this.transformQueries()
        }
      };

      $api
        .request(params)
        .then(async res => {
          this.items = this.setIndex(this.flatten(res.lcpools, 'lcmeta'));
          this.totalCount = res.maxNum;
          // ページングによる取得の場合は選択中のアイテムと同じ送信元企業ID、銀行取引先番号(LCオリジナル)のレコードにチェックを入れる
          if (currentPage && this.selectItem) {
            this.selecting = true;
            await this.$refs.table.clearSelection();
            const selectItem = this.selectItem;
            const filteredItems = _.filter(this.items, item => {
              return item.senderCompanyId === selectItem.senderCompanyId && item.ourReferenceNoLcOriginal === selectItem.ourReferenceNoLcOriginal;
            });
            for (let i = 0; i < filteredItems.length; i++) {
              await this.$refs.table.toggleRowSelection(filteredItems[i], true);
            }
            this.selecting = false;
          }
          // 選択中のデータには選択済みフラグを追加する
          if (this.lc && this.lc) {
            this.items = _.map(this.items, item => {
              if (
                _.some(this.lc, lc => {
                  return lc.senderCompanyId === item.senderCompanyId && lc.ourReferenceNoLcOriginal === item.ourReferenceNoLcOriginal;
                })
              ) {
                item.poolSelected = true;
              }
              return item;
            });
          }
        })
        .catch(err => {
          this.items = [];
          if (err.statusCode !== 403) this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    search() {
      this.offset = 0;
      this.currentPage = 1;
      this.selectionItems = [];
      this.selectItem = null;
      this.items = null;
      this.$store.commit('SET_QUERIES', { key: 'LC_POOL_SELECT', queries: _.cloneDeep(this.searchForm) });
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', { key: 'LC_POOL_SELECT', queries: _.cloneDeep(this.searchForm) });
    },
    // eslint-disable-next-line no-unused-vars
    onPaging({ target, sortType, currentPage }) {
      this.offset = currentPage * this.limit - this.limit;
      this.currentPage = currentPage;
      this.fetch(currentPage);
    },
    async register() {
      if (this.selecting) return;
      this.selecting = true;
      await this.getSelectionItems(this.selectItem);
      // console.log(this.selectionItems)
      this.$emit('add-lc', this.selectionItems);
    },
    // テーブル表示時にrow-keyを一意にするためにrowにcurrentPageとindexを付与します
    setIndex(array) {
      for (let i = 0; i < array.length; i++) {
        array[i] = { ...array[i], currentPage: this.currentPage, index: i };
      }
      return array;
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return { ..._.omit(o, [target]), ...o.lcmeta };
      });
    },
    // テーブルのrow-keyを返却します（チェック状態保持）
    getRowKey(row) {
      // NOTE: currentPageとindexで一意にして返却
      return `${row.currentPage}_${row.index}`;
    },
    async rowClick(row) {
      if (this.selecting) return;
      this.$refs.table.toggleRowSelection(row);
    },
    // セレクト切り替え
    async selectionChange(multipleSelection) {
      if (this.selecting) return; // 選択処理中はreturn
      else {
        this.selecting = true;
        this.selectionItems = [];
        if (this.selectItem) {
          // 選択中のアイテムがある場合は、選択中のアイテムを全て解除
          const includeItems = _.filter(multipleSelection, item => {
            return item.senderCompanyId === this.selectItem.senderCompanyId && item.ourReferenceNoLcOriginal === this.selectItem.ourReferenceNoLcOriginal;
          });
          for (let i = 0; i < includeItems.length; i++) {
            await this.$refs.table.toggleRowSelection(includeItems[i], false);
          }

          // 新規に選択したアイテム multipleSelection内の選択中のアイテム以外のもの
          const newItem = _.find(multipleSelection, item => {
            return item.senderCompanyId !== this.selectItem.senderCompanyId || item.ourReferenceNoLcOriginal !== this.selectItem.ourReferenceNoLcOriginal;
          });
          if (newItem) {
            // 新規に選択したアイテムがある場合は、同じIDのものに全てチェックを入れる
            await this.addSelection(newItem);
            this.selecting = false;
            // console.log(this.selectionItems);
          } else {
            // ない場合はチェックを全て解除して処理終了
            this.selectItem = null;
            this.selecting = false;
            return;
          }
        } else {
          // 選択中のアイテムがない場合は一番上のアイテムで追加する
          const selectItem = _.cloneDeep(multipleSelection[0]);
          await this.addSelection(selectItem);
          this.selecting = false;
          // console.log(this.selectionItems);
        }
      }
    },
    // 同じ送信元企業ID、銀行取引先番号(LCオリジナル)の書類全件を追加選択
    async addSelection(selectItem) {
      this.selectItem = selectItem;
      const filteredItems = _.filter(this.items, item => {
        return item.senderCompanyId === selectItem.senderCompanyId && item.ourReferenceNoLcOriginal === selectItem.ourReferenceNoLcOriginal;
      });
      for (let i = 0; i < filteredItems.length; i++) {
        await this.$refs.table.toggleRowSelection(filteredItems[i], true);
      }
      // await this.getSelectionItems(selectItem)
    },
    // 同じ送信元企業ID、銀行取引先番号(LCオリジナル)の書類全件を取得
    async getSelectionItems(selectItem) {
      this.registering = true;
      // BFF_LCP02 L/Cプール一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/lc-pool-datas/search/{pageLine}/{pageNum}',
          path: {
            pageLine: this.totalCount,
            pageNum: 1
          },
          query: {
            ...this.transformQueries(),
            ourReferenceNoLcOriginal: selectItem.ourReferenceNoLcOriginal
          }
        }
      };
      await $api
        .request(params)
        .then(async res => {
          const items = this.flatten(res.lcpools, 'lcmeta');
          const filtered = _.filter(items, item => {
            return item.ourReferenceNoLcOriginal === selectItem.ourReferenceNoLcOriginal && item.senderCompanyId === selectItem.senderCompanyId;
          });
          this.selectionItems = filtered;
        })
        .catch(async err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          this.selectionItems = [];
        });
      this.selecting = false;
      this.registering = false;
    }
  }
};
</script>

<style lang="scss">
.el-dialog__wrapper[aria-label='Select L/C'] .el-dialog {
  .dialog_container {
    padding-bottom: 40px;
  }

  .el-dialog__body {
    padding-top: 12px;
    padding-bottom: 0;
  }

  h2 {
    margin: 0 0 24px;
    padding: 12px 0;
    border-bottom: 1px solid $color_gray_300;
    color: $color_gray_800;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  // .el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
  //   width: 220px;
  // }

  // selection 全選択チェックボックスは非表示
  .el-table__header thead .el-table-column--selection {
    .cell {
      display: none;
    }
  }
}

.tw_button__icon.icon_search {
  padding-left: 12px;
  padding-right: 20px;
}

.row.register {
  display: flex;
  justify-content: right;
  .tw_button {
    padding: 7px 40px;
  }
}
</style>
